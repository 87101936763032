import { Component, OnInit } from '@angular/core';
import { TimeZone } from '../../../models/country.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountsService } from '../../../services/accounts.service';
import { ToastService } from '../../../services/toast.service';
import { SettingsService } from '../../../services/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PurecloudInfo, RegionDomainName, regionList } from '../models/region.model';
import { OrganizationService } from '../../../services/organization.service';
import { PremiumAppStatus } from '../models/install.model';
import { CountryService } from '../../../services/country.service';
import { SEVERITY } from '../../../models/severity.model';
import { AuthorizationService } from '../../../services/authorization.service';
import { languages } from '../../../models/languages.model';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss']
})
export class InstallComponent implements OnInit {
  timeZones!: TimeZone[];
  redirect!: string;
  region!: string;
  regionId!: string;
  token!: string;
  appAvailable: boolean = false;
  step = 1;
  installForm!: FormGroup;
  installationStarted: boolean = false;

  allLanguages = languages;

  lang = localStorage.getItem('lang');
  selectedLanguage: string = this.lang || 'en';

  constructor(
    private accountService: AccountsService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private settings: SettingsService,
    private activatedRoute: ActivatedRoute,
    private orgService: OrganizationService,
    private router: Router,
    private countryService: CountryService,
    private authService: AuthorizationService,
    private translateService: TranslateService,
    private config: PrimeNGConfig
  ) {
    this.redirect = `${this.settings.baseUrl}install`;
    this.initializeRegion();
  }

  ngOnInit(): void {
    this.setupForm();
    this.initializeToken();
  }

  /**
   * Initializes region information from route parameters or local storage.
   */
  private initializeRegion(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['environment']) {
        const environment = params['environment'];
        const regionData = regionList.find((region) => region.regionId === environment);

        if (regionData) {
          this.region = regionData.regionName;
          localStorage.setItem('reg', this.region);
          this.registerOrganisation();
        }
      }
    });

    if (!this.region && localStorage.getItem('reg')) {
      this.region = localStorage.getItem('reg')!;
    }
  }

  /**
   * Sets up the reactive form.
   */
  private setupForm(): void {
    this.installForm = this.fb.group({
      timezone: ['', Validators.required]
    });
  }

  /**
   * Extracts token from the URL hash.
   */
  private initializeToken(): void {
    if (window.location.hash) {
      this.token = this.getParameterByName('access_token');

      if (this.token) {
        this.checkAppAvailability();
      }
    }
  }

  /**
   * Registers the organization using the provided region information.
   */
  private registerOrganisation(): void {
    const domainName = RegionDomainName[this.region as keyof typeof RegionDomainName];
    this.accountService.getCredential(PurecloudInfo.clientId, this.redirect, domainName);
  }

  /**
   * Checks app availability during initialization.
   */
  private checkAppAvailability(): void {
    if (!this.token || !this.region) return;

    const data = { Token: this.token, Region: this.region };
    this.orgService.checkPremiumAppStatus(data).subscribe({
      next: (response) => {
        switch (response) {
          case PremiumAppStatus.NotAvailable:
            this.appAvailable = false;
            break;
          case PremiumAppStatus.Available:
            this.appAvailable = true;
            break;
          case PremiumAppStatus.AlreadyInstalled:
            this.router.navigate(['login']);
            break;
          default:
          // this.toastService.error('Unexpected response for app availability.');
        }
      },
      error: (err) => {
        console.error('Error checking app availability:', err);
        // this.toastService.error('Failed to check app availability.');
      }
    });
  }

  /**
   * Loads available time zones from the CountryService.
   */
  private loadTimeZones(): void {
    this.countryService.getTimeZones().subscribe({
      next: (response) => {
        this.timeZones = response;
      },
      error: (err) => {
        console.error('Error loading time zones:', err);
      }
    });
  }

  /**
   * Extracts a parameter value from the URL hash.
   * @param name Parameter name to extract.
   * @returns Parameter value or an empty string.
   */
  private getParameterByName(name: string): string {
    const regex = new RegExp(`[\\#&]${name}=([^&#]*)`);
    const results = regex.exec(window.location.hash);
    return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : '';
  }

  registerOrg() {
    if (!this.appAvailable) {
      this.toastService.add({ severity: SEVERITY.ERROR, summary: 'register.appNotAvailable', detail: 'register.appNotAvailableDetail' });
      return;
    }
    try {
      const data = {
        Token: this.token,
        Region: this.region,
      };
      this.orgService.registerOrg(data).subscribe({
        next: (response) => {
          this.authService.saveAccessToken(response);
          this.step = 2;
          this.loadTimeZones();
        },
        error: (error) => {
          console.error(error);
        }
      })
    } catch (e) {
      console.log(e);
    }
  }

  installOrg() {
    this.installForm.markAllAsTouched();
    if (!this.installForm.valid) {
      return;
    }
    try {
      const data = {
        timezone: this.installForm.get('timezone')?.value
      };
      this.installationStarted = true;
      this.orgService.installOrg(data).subscribe({
        next: (response) => {
          this.toastService.add({ severity: SEVERITY.SUCCESS, summary: response.message, detail: response.code });
          this.router.navigate(['login']);
        },
        complete: () => {
          this.installationStarted = false;
        }
      })
    } catch (e) {
      this.installationStarted = false;
      console.log(e);
    }
  }

  translate(lang: string) {
    // Save the selected language to localStorage
    localStorage.setItem('lang', lang);

    // Use the selected language for translation
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
  }
}
