import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ) {
    let lang = localStorage.getItem('lang');
    this.translateService.use(lang || 'en');
  }

  ngOnInit() {
    this.config.ripple = true;
  }
}