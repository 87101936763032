import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';

@Injectable()
export class BaseUserGuard {
  constructor(
    private authService: AuthorizationService,
    private router: Router
  ) {}

  canActivate(): boolean {
    const isLoggedIn = this.authService.isUserLoggedIn();
    if (isLoggedIn) {
      return true;
    } else {
      // Get the current URL correctly, including query params if any
      const currentUrl = location.href; 

      // Encode the URL for safe inclusion as a query parameter
      const urlPath = currentUrl.split(window.location.origin)[1];
      const encodedUrl = encodeURIComponent(urlPath);

      // Redirect to the login page, preserving the current URL in the 'redirect' query parameter
      this.router.navigate(['login'], { queryParams: { redirect: encodedUrl } });

      return false;
    }
  }
}
