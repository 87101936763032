import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent
  ],
  imports: [
    TranslateModule,
    CommonModule,
    RouterModule,
    ButtonModule,
    ButtonModule,
    AvatarModule,
    DropdownModule,
    OverlayPanelModule,
    DividerModule,
    FormsModule,
  ],
  exports: [
    LayoutComponent,
    SidebarComponent,
    TopbarComponent
  ]
})
export class LayoutModule { }
