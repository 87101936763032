import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { ErrorPageComponent } from '../modules/pages/error-page/error-page.component';
import { LoginComponent } from '../modules/pages/login/login.component';
import { BaseUserGuard } from '../guards/baseguard.gaurd';
import { LoginRouteGuard } from '../guards/login.guard';
import { InstallComponent } from '../modules/pages/install/install.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [BaseUserGuard],
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/tickets',
        pathMatch: 'full'
      },
      {
        path: 'tickets',
        loadChildren:
          () => import('../modules/tickets/tickets.module').then(m => m.TicketsModule)
      },
      {
        path: 'categories',
        loadChildren:
          () => import('../modules/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'dashboard',
        loadChildren:
          () => import('../modules/dashboards/dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: 'customers',
        loadChildren:
          () => import('../modules/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'settings',
        loadChildren:
          () => import('../modules/settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  },
  {
    path: 'login',
    canActivate: [LoginRouteGuard],
    component: LoginComponent
  },
  {
    path: 'install',
    component: InstallComponent
  },
  {
    path: 'install/:environment',
    component: InstallComponent
  },
  { path: '404', component: ErrorPageComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
