import { Component } from '@angular/core';
import { AuthorizationService } from '../../services/authorization.service';
import { User } from '../../models/credential.model';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { languages } from '../../models/languages.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  user!: User;

  allLanguages = languages;

  lang = localStorage.getItem('lang');
  selectedLanguage: string = this.lang || 'en';

  constructor(
    private authService: AuthorizationService,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    private router: Router
  ) {
    const userData = localStorage.getItem('PearlCXUser');
    if (userData) {
      try {
        this.user = JSON.parse(userData);
      } catch (error) {
        this.logout();
      }
    }
  }

  logout() {
    this.authService.logoutUser();
  }

  translate(lang: string) {
    // Save the selected language to localStorage
    localStorage.setItem('lang', lang);

    // Use the selected language for translation
    this.translateService.use(lang);
    this.translateService
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
  }

  navigateToTicketCreate() {
    this.router.navigate(['tickets', 'details']);
  }
}
