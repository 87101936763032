import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimeNGConfig } from 'primeng/api';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PagesModule } from '../modules/pages/pages.module';
import { CoreModule } from '../modules/core/core.module';
import { SettingsService } from '../services/settings.service';
import { Interceptor } from '../services/interceptor';
import { LayoutModule } from '../layout/layout.module';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

// Required for AoT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

function envLoadFactory(settings: SettingsService) {
  return (): Promise<void> => {
    return settings.updateEnv();
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PagesModule,
    ToastModule,
    ConfirmDialogModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: envLoadFactory, deps: [SettingsService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    PrimeNGConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }