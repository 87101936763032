import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { Observable } from 'rxjs';
import { Organization } from '../models/organization.model';
import { ApiResponse, OAuthToken } from '../models/credential.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private BaseUrl: string;

  constructor(private http: HttpClient, private settings: SettingsService) { 
    this.BaseUrl = this.settings.ApiBaseUrl + 'Organization/';
  }

  getOrgnanizationSettings(): Observable<Organization> {
    return this.http.get<Organization>(this.BaseUrl + 'settings');
  }

  setOrganizationSettings(data: Organization): Observable<Organization> {
    return this.http.put<Organization>(this.BaseUrl + 'settings', data);
  }

  getOrgBasicDetails(): Observable<Organization> {
    return this.http.get<Organization>(this.BaseUrl + 'me');
  }

  checkPremiumAppStatus(data: { Token: string; Region: string; }): Observable<number> {
    return this.http.post<number>(this.BaseUrl + 'genesys/status', data);
  }

  registerOrg(data: { Token: string; Region: string; }): Observable<OAuthToken> {
    return this.http.post<OAuthToken>(this.BaseUrl + 'register', data);
  }

  installOrg(data: {timezone: string}): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.BaseUrl + 'install', data);
  }
}
