<div
  class="login-container flex align-items-center justify-content-center min-h-screen bg-gray-100"
>
  <div class="p-card p-4 w-full w-10 md:w-6 h-30rem">
    <div
      class="flex flex-column md:flex-row align-items-center gap-4 justify-content-between h-full"
    >
      <!-- Logo Section -->
      <div class="logo-container flex justify-content-center sm:w-6">
        <img
          src="https://app.feebak.com/assets/home/feebak.png"
          alt="Logo"
          class="logo-img"
        />
      </div>
      <p-divider layout="vertical" class="hidden sm:block h-full" />
      <!-- Login Form Section -->
      <div class="login-form-container flex gap-3 flex-column sm:w-6 w-full">
        <form [formGroup]="userForm">
          <div>
            <h1>{{ "login" | translate }}</h1>
            <p>{{ "loginInfo" | translate }}</p>
          </div>
          <div class="flex flex-column gap-3">
            <!-- Organization Name Input -->
            <div class="flex flex-column gap-2 w-full">
              <label for="organizationName">{{
                "organizationName" | translate
              }}</label>
              <input
                pInputText
                placeholder="{{ 'organizationNamePlaceholder' | translate }}"
                formControlName="organizationName"
                class="w-full"
              />
            </div>
            <!-- Region Picker -->
            <div class="flex flex-column gap-2 w-full">
              <label for="regionName">{{ "selectRegion" | translate }}</label>
              <p-dropdown
                [options]="regionDisplayNames"
                placeholder="{{ 'selectRegionPlaceholder' | translate }}"
                formControlName="regionName"
                styleClass="w-full"
                [showClear]="true"
                (onChange)="getRegion()"
              />
            </div>
          </div>
          <p-button
            label="{{ 'loginWithGenesys' | translate }}"
            styleClass="w-full mt-4"
            (onClick)="onLogin()"
            [disabled]="userForm.invalid"
          />
        </form>
      </div>
    </div>
  </div>
</div>
