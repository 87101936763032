export enum SEVERITY {
    SUCCESS = 'success',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
    CONTRAST = 'contrast',
    SECODARY = 'secondary'
}

export enum RequestType {
    OUTGOING = "outgoing"
}