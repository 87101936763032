import { ModuleWithProviders, NgModule } from '@angular/core';
import { AccountsService } from '../../services/accounts.service';
import { SettingsService } from '../../services/settings.service';
import { AuthorizationService } from '../../services/authorization.service';
import { BaseUserGuard } from '../../guards/baseguard.gaurd';
import { LoginRouteGuard } from '../../guards/login.guard';
import { ConfirmationService, MessageService } from 'primeng/api';

@NgModule({
  declarations: [],
  providers: [AccountsService, SettingsService, AuthorizationService, BaseUserGuard, LoginRouteGuard, MessageService, ConfirmationService],
})

export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule
    };
  }
}
