import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { DividerModule } from 'primeng/divider';
import { FloatLabelModule } from 'primeng/floatlabel';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { SkeletonModule } from 'primeng/skeleton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { JsonEditorDirective } from '../../services/json-editor.directive';

@NgModule({
  declarations: [JsonEditorDirective],
  imports: [],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    TableModule,
    AutoCompleteModule,
    ButtonModule,
    IconFieldModule,
    InputIconModule,
    TagModule,
    AvatarModule,
    AvatarGroupModule,
    SidebarModule,
    MultiSelectModule,
    FloatLabelModule,
    DividerModule,
    CheckboxModule,
    PaginatorModule,
    CalendarModule,
    TranslateModule,
    ChipModule,
    CardModule,
    MenuModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    InputGroupModule,
    InputGroupAddonModule,
    SkeletonModule,
    RadioButtonModule,
    JsonEditorDirective
  ]
})

export class SharedModule { }
