export const RegionDomainName = {
    Dublin: 'mypurecloud.ie',
    Americas: 'mypurecloud.com',
    Sydney: 'mypurecloud.com.au',
    Frankfurt: 'mypurecloud.de',
    Tokyo: 'mypurecloud.jp',
    AmericasWest: 'usw2.pure.cloud',
    Canada: 'cac1.pure.cloud',
    London: 'euw2.pure.cloud',
    Seoul: 'apne2.pure.cloud',
    Mumbai: 'aps1.pure.cloud',
    SouthAmerica: 'sae1.pure.cloud',
    MiddleEast: 'mec1.pure.cloud'
};

export const PurecloudInfo = {
    clientId: '4467e856-feea-42a8-8e98-80054b8f74d2'
};

export interface Region {
    regionName: string;
    regionDisplayName: string;
    regionId: string;
}

export const regionList: Region[] = [
    {
        regionName: 'Dublin',
        regionDisplayName: 'Dublin',
        regionId: 'mypurecloud.ie'
    },
    {
        regionName: 'Americas',
        regionDisplayName: 'Americas (East)',
        regionId: 'mypurecloud.com'
    },
    {
        regionName: 'Sydney',
        regionDisplayName: 'Sydney',
        regionId: 'mypurecloud.com.au'
    },
    {
        regionName: 'Tokyo',
        regionDisplayName: 'Tokyo',
        regionId: 'mypurecloud.jp'
    },
    {
        regionName: 'Frankfurt',
        regionDisplayName: 'Frankfurt',
        regionId: 'mypurecloud.de'
    },
    {
        regionName: 'AmericasWest',
        regionDisplayName: 'Americas (West)',
        regionId: 'usw2.pure.cloud'
    },
    {
        regionName: 'Canada',
        regionDisplayName: 'Canada',
        regionId: 'cac1.pure.cloud'
    },
    {
        regionName: 'London',
        regionDisplayName: 'London',
        regionId: 'euw2.pure.cloud'
    },
    {
        regionName: 'Seoul',
        regionDisplayName: 'Seoul',
        regionId: 'apne2.pure.cloud'
    },
    {
        regionName: 'Mumbai',
        regionDisplayName: 'Mumbai',
        regionId: 'aps1.pure.cloud'
    },
    {
        regionName: 'SouthAmerica',
        regionDisplayName: 'Americas (São Paulo)',
        regionId: 'sae1.pure.cloud'
    },
    {
        regionName: 'MiddleEast',
        regionDisplayName: 'Middle East (UAE)',
        regionId: 'mec1.pure.cloud'
    }
];