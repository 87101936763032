<div
  class="flex flex-column align-items-center justify-content-center min-h-screen bg-gray-100"
>
  <div class="p-card p-4 w-10 md:w-6 h-30rem">
    <!-- First Installation Page -->
    <div
      *ngIf="step === 1"
      class="flex flex-column md:flex-row align-items-center gap-4 h-full"
    >
      <div class="flex justify-content-center w-full md:w-6">
        <img
          src="https://app.feebak.com/assets/home/feebak.png"
          alt="{{ 'pearlCX' }}"
          class="w-12rem"
        />
      </div>

      <p-divider layout="vertical" class="h-full hidden md:block" />

      <div class="w-full md:w-6">
        <p class="flex align-items-center gap-2 text-lg">
          <i
            class="pi"
            [ngClass]="{
              'pi-check-circle text-green-500': appAvailable,
              'pi-times-circle text-red-500': !appAvailable
            }"
          ></i>
          <span>{{
            appAvailable
              ? ("register.productAvailable" | translate)
              : ("register.productUnavailable" | translate)
          }}</span>
        </p>
        <h2>{{ "register.welcome" | translate }}</h2>
        <p>
          {{ "register.description" | translate }}
        </p>
        <p-button
          label="Start"
          [disabled]="!appAvailable"
          (onClick)="registerOrg()"
          class="mt-3"
        ></p-button>
      </div>
    </div>

    <!-- Second Installation Page -->
    <div
      *ngIf="step === 2"
      class="flex flex-column md:flex-row align-items-center gap-4 h-full"
    >
      <div class="w-full md:w-6">
        <h3>{{ "register.weInstall" | translate }}</h3>
        <ul class="list-decimal">
          <li>{{ "roles" | translate }}</li>
          <li>{{ "groups" | translate }}</li>
          <li>{{ "appInstances" | translate }}</li>
          <li>{{ "oauths" | translate }}</li>
        </ul>
      </div>

      <p-divider layout="vertical" class="h-full hidden md:block" />

      <div class="w-full md:w-6 flex flex-column gap-3">
        <h3>{{ "register.oneMoreStep" | translate }}</h3>
        <div class="flex flex-column gap-2" [formGroup]="installForm">
          <label for="timezone">{{ "register.timezone" | translate }}</label>
          <p-dropdown
            styleClass="w-12"
            #timeZone
            formControlName="timezone"
            [options]="timeZones"
            [filter]="true"
            optionLabel="zone"
            optionValue="zone"
            placeholder="{{ 'register.timezonePlaceholder' | translate }}"
          />
        </div>

        <p-button
          label="Install"
          class="mt-4"
          (onClick)="installOrg()"
        ></p-button>
        <p *ngIf="installationStarted" class="text-sm text-gray-500 mt-2">
          {{ "register.pleaseWait" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="flex gap-3 align-items-center absolute bottom-0 mb-3">
    <label for="languagePicker">{{ "language" | translate }}</label>
    <p-dropdown
      #languagePicker
      [options]="allLanguages"
      optionLabel="label"
      optionValue="value"
      [(ngModel)]="selectedLanguage"
      placeholder="Select Language"
      styleClass="w-full"
      (onChange)="translate(selectedLanguage)"
    />
  </div>
</div>
