import { Injectable } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { SettingsService } from './settings.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { RequestType, SEVERITY } from '../models/severity.model';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    private authService: AuthorizationService,
    private settings: SettingsService,
    private toastService: ToastService,
    private router: Router
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (
      req.body &&
      !req.body.append &&
      !req.headers.has('Content-Type') &&
      req.method !== 'GET'
    ) {
      if (!req.headers.has(RequestType.OUTGOING)) {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json')
        });
      }
    }

    if (!req.headers.has(RequestType.OUTGOING) && !req.headers.has('Authorization')) {
      const token = this.authService.getAccessToken();
      if (token) {
        req = req.clone({
          setHeaders: { 'Authorization': 'Bearer ' + token }
        });
      }
      req = req.clone({
        setHeaders: { 'API-KEY': this.settings.APIKey }
      });
      req = req.clone({
        withCredentials: false
      });

      const lang = localStorage.getItem('lang');
      if (lang) {
        req = req.clone({
          setHeaders: { 'Accept-Language': lang }
        });
      }
    } else {
      req = req.clone({
        headers: req.headers.delete(RequestType.OUTGOING)
      });
    }

    return next.handle(req).pipe(
      tap({
        next: () => {
          // Handle successful events here if needed
        },
        error: (err) => {
          let errorMessage = 'An unexpected error occurred.';
          let errorSummary = `Error Code: ${err.status}`;

          // Check for error details in the response
          if (err.error) {
            if (typeof err.error === 'string') {
              errorMessage = err.error;
            } else if (err.error.message) {
              errorMessage = err.error.message;
            }

            if (err.error.code) {
              errorSummary = err.error.code;
            }
          }

          this.toastService.add({
            severity: SEVERITY.ERROR,
            summary: errorSummary,
            detail: errorMessage
          });

          // Handle specific HTTP status codes if needed
          if (err.status === 401) {
            // Optionally, log out the user or redirect
            this.authService.clearToken();
            localStorage.removeItem('PearlCXUser');
            localStorage.removeItem('PearlCXOrg');
            this.router.navigate(['login']);
          }
        },
        complete: () => {
          // Handle completion if necessary
        }
      })
    );
  }
}