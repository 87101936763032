import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Country, State, TimeZone } from '../models/country.model';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private BaseUrl: string;
  private timeZoneUrl: string;

  constructor(private http: HttpClient, private settings: SettingsService) { 
    this.BaseUrl = this.settings.ApiBaseUrl + 'Countries/';
    this.timeZoneUrl = this.settings.ApiBaseUrl + 'timezones/';
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.BaseUrl);
  }

  getStates(countryCode: string): Observable<State[]> {
    return this.http.get<State[]>(this.BaseUrl + countryCode.toUpperCase() + '/state');
  }

  getTimeZones(): Observable<TimeZone[]> {
    return this.http.get<TimeZone[]>(this.timeZoneUrl);
  }
}
