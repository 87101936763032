import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { SettingsService } from './settings.service';
import {
  User,
  OAuthToken,
  ClientCredential
} from '../models/credential.model';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class AccountsService {
  private BaseUrl: string;
  private BaseUrl2: string;
  private UserUrl: string;
  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private router: Router
  ) {
    this.BaseUrl = this.settings.ApiBaseUrl + 'oauth/';
    this.BaseUrl2 = this.settings.ApiBaseUrl + 'Organization/';
    this.UserUrl = this.settings.ApiBaseUrl + 'Users/';
  }

  relogin(token: string, region: string): Observable<OAuthToken> {
    const body = {
      AccessToken: token,
      Region: region
    };
    return this.http
      .post<OAuthToken>(this.BaseUrl + 'genesys/login', body);
  }

  isLogin(token: string): Observable<User> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<User>(this.UserUrl + 'me', { headers });
  }

  logout() {
    return this.http
      .post<User>(this.BaseUrl + 'Logout', {});
  }

  logoutToken(token: string) {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http
      .post<User>(this.BaseUrl + 'Logout', {}, { headers });
  }

  getRefreshToken() {
    return this.http
      .post<OAuthToken>(this.BaseUrl + 'RefreshToken', {});
  }

  getClientId(
    orgName: string,
    region: string
  ): Observable<ClientCredential> {
    // const headers = new HttpHeaders().set('Authorization', this.authToken.getToken());
    const params = new HttpParams()
      .set('name', orgName)
      .set('region', region);
    return this.http
      .get<ClientCredential>(
        this.BaseUrl2 + 'implicit/client',
        { params: params }
      );
  }

  getCredential(clientId: string, redirect: string, reg: string, state: string | null = null): Observable<any> {
    let params = new HttpParams()
      .set('response_type', 'token')
      .set('client_id', clientId)
      .set('redirect_uri', redirect);

    if (state) {
      params = params.set('state', state);
    }

    console.log(params.toString());

    // Attempt to redirect, and return success or error in the observable
    try {
      // Trigger the redirection
      window.location.replace('https://login.' + reg + '/oauth/authorize?' + params.toString());

      // If successful, return a success message wrapped in an observable
      return of({ success: true, message: 'Redirecting to authorization.' });
    } catch (error) {
      // If an error occurs, return an error message wrapped in an observable
      return of({ success: false, message: 'Error in redirecting.' });
    }
  }

}
