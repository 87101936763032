import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  constructor(private router: Router) {}

  // Navigation links configuration
  navLinks = [
    { label: 'dashboard', path: '/dashboard', icon: 'pi pi-chart-bar' },
    { label: 'tickets', path: '/tickets', icon: 'pi pi-ticket' },
    { label: 'categories', path: '/categories', icon: 'pi pi-bars' },
    { label: 'customers', path: '/customers', icon: 'pi pi-users' },
    { label: 'settings', path: '/settings', icon: 'pi pi-cog' },
  ];

  /**
   * Navigate to the given path
   * @param path Navigation path
   */
  navigate(path: string): void {
    this.router.navigate([path]);
  }

  /**
   * Check if the given path is active
   * @param path Navigation path
   * @returns True if active, otherwise false
   */
  isActive(path: string): boolean {
    return this.router.url.includes(path);
  }
}
