<div class="top-bar flex justify-content-between align-items-center px-3">
    <p>PearlCX Case Management</p>

    <div class="profile-menu flex align-items-center gap-3">
        <p-button label="{{'addTicket' | translate}}" icon="pi pi-plus" (onClick)="navigateToTicketCreate()" />
        <!-- Overlay Panel -->
        <p-overlayPanel #overlay styleClass="p-3">
            <!-- User Information -->
            <div class="flex align-items-center gap-3">
                <p-avatar *ngIf="user.profilePicture" [image]="user.profilePicture" shape="circle" />
                <p-avatar *ngIf="!user.profilePicture" icon="pi pi-user" shape="circle" />
                <div class="flex flex-column">
                    <span class="font-bold">{{ user.name }}</span>
                    <span class="text-sm">{{ user.email }}</span>
                </div>
            </div>
            <p-divider />

            <div class="flex flex-column gap-3">
                <!-- Language Selector -->
                <div class="flex flex-column">
                    <label for="languagePicker">{{'language' | translate}}</label>
                    <p-dropdown #languagePicker [options]="allLanguages" optionLabel="label" optionValue="value"
                        [(ngModel)]="selectedLanguage" placeholder="Select Language" styleClass="w-full"
                        (onChange)="translate(selectedLanguage)" />
                </div>

                <!-- Logout Button -->
                <p-button (onClick)="logout()" label="{{'logout' | translate}}" icon="pi pi-power-off"
                    styleClass="w-full" />
            </div>

        </p-overlayPanel>

        <!-- Trigger Avatar -->
        <p-avatar *ngIf="user?.profilePicture" [image]="user.profilePicture" shape="circle"
            (click)="overlay.toggle($event)" />

        <!-- Default Icon if No Profile Picture -->
        <p-button *ngIf="!user?.profilePicture" icon="pi pi-user" [rounded]="true" [outlined]="true"
            (onClick)="overlay.toggle($event)" styleClass="text-white border-white" />
    </div>
</div>