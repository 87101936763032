import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  add(data: Message) {
    // Translate the summary and detail if provided
    let translatedSummary = null;
    if (data.summary) {
      this.translateText(data.summary).subscribe({
        next: (response) => {
          translatedSummary = response;
        }
      })
    }

    let translatedDetail = null;
    if (data.detail) {
      this.translateText(data.detail).subscribe({
        next: (response) => {
          translatedDetail = response;
        }
      })
    }

    // Add the translated message
    this.messageService.add({
      ...data,
      summary: translatedSummary || data.summary,
      detail: translatedDetail || data.detail,
    });
  }

  private translateText(key: string): Observable<string> {
    return this.translateService.get(key);
  }
}