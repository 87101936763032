import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class SettingsService {
  BaseUrl: string;
  ApiBaseUrl: string;
  AssetUrl: string;
  baseUrl: string;
  APIKey: string;
  Environment: string;
  isEnvUpdated: boolean = false;
  constructor(private router: Router) {
    this.ApiBaseUrl = environment.apiBaseUrl;
    this.BaseUrl = environment.baseUrl;
    this.AssetUrl = this.BaseUrl + 'assets/';
    this.baseUrl = environment.baseUrl;
    this.APIKey = environment.APIKey;
    this.Environment = environment.environment;
  }
  updateSettings(data: { apiBaseUrl: string; baseUrl: string; APIKey: string; environment: string; dsn: string; FbAppId: string; Domain: string; }) {
    this.ApiBaseUrl = data.apiBaseUrl + '/' || environment.apiBaseUrl;
    this.BaseUrl = data.baseUrl || environment.baseUrl;
    this.AssetUrl = this.BaseUrl + 'assets/';
    this.baseUrl = data.baseUrl || environment.baseUrl;
    this.APIKey = data.APIKey || environment.APIKey;
    this.Environment = data.environment || environment.environment;
    this.isEnvUpdated = true;
  }
  async updateEnv() {
    if (this.isEnvUpdated)
      return;
    try {
      const baseUrl = window.location.origin + '/';
      const response = await fetch(baseUrl + "env.json")
      if (response.ok) {
        const data = await response.json();
        if (data != null) {
          this.updateSettings(data);
        }
      }
    } catch (e: unknown) {
      const error = e as Error;
      console.log(error.message);
    }
  }
}
