import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthToken, User } from '../models/credential.model';
import { AccountsService } from './accounts.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public isLoggedIn!: boolean;
  public AccessToken: string | null;
  private loggedInUser!: User;

  // Notifier for auth change
  private authChangeSubject: Subject<User> = new Subject<User>();
  public authChange = this.authChangeSubject.asObservable();

  constructor(
    private router: Router,
    private accountService: AccountsService
  ) {
    this.AccessToken = localStorage.getItem('Access-Token');
  }

  isUserLoggedIn() {
    if (this.isLoggedIn) {
      return this.isLoggedIn;
    } else {
      this.loadUser();
      return this.isLoggedIn;
    }
  }

  getAccessToken() {
    return localStorage.getItem('Access-Token');
  }

  saveAccessToken(oauthToken: OAuthToken) {
    if (oauthToken && oauthToken.access_token) {
      localStorage.setItem('Access-Token', oauthToken.access_token);
      this.AccessToken = oauthToken.access_token;
    }
  }

  clearToken() {
    localStorage.removeItem('Access-Token');
    this.isLoggedIn = false;
    this.AccessToken = null;
  }

  private loadUser() {
    if (this.loggedInUser) {
      return;
    }
    const User = localStorage.getItem('PearlCXUser');
    if (User && User !== '') {
      try {
        const user: User = JSON.parse(User);
        this.loggedInUser = user;
        this.isLoggedIn = true;
      } catch (e) {
        this.logoutUser();
      }
    }
  }

  getUser(): User | null {
    if (this.isLoggedIn) return this.loggedInUser;
    else {
      this.loadUser();
      if (this.isLoggedIn) {
        return this.loggedInUser;
      } else {
        this.logoutUser();
        return null;
      }
    }
  }

  logoutUser() {
    if (this.loggedInUser) {
      this.OnLogout();
    }
  }

  OnLogout() {
    this.logoutUserToken();
    localStorage.removeItem('PearlCXUser');
    localStorage.removeItem('PearlCXOrg');
    this.clearToken();
    this.router.navigate(['login']);
  }

  refreshToken(isAgent?: boolean, state: string | null = null) {
    const token = localStorage.getItem('Access-Token');
    if (!token) {
      return;
    }
    this.accountService.isLogin(token).subscribe({
      next: (response) => {
        if (response) {
          const user = response;
          if (user) {
            this.loginUser(user, isAgent);
          }
        }
      },
      error: (err) => {
        if (err?.status === 401) {
          this.logoutUser();
        } else {
          console.error('An error occurred during token refresh:', err);
        }
      },
      complete: () => {
        console.log('Token refresh process completed.');
        this.router.navigate([state]);
      }
    });
  }

  getTimeUntilTokenExpiryInHours(token: string): number {
    return (JSON.parse(atob(token.split('.')[1])).exp * 1000 - Date.now()) / (1000 * 60 * 60);
  }

  refresh() {
    const oldToken = localStorage.getItem('Access-Token');
    if (!oldToken) {
      return;
    }

    const timeRemaining = this.getTimeUntilTokenExpiryInHours(oldToken);

    if (timeRemaining < 2) {
      this.accountService.getRefreshToken().subscribe({
        next: (resp) => {
            this.OnLogout();

          if (resp) {
            this.saveAccessToken(resp);

            // Logout previous token
            if (resp?.access_token) {
              this.logoutUserToken(oldToken);
            }

            // Notify that the user is logged in
            this.notifyAuthChanged(this.loggedInUser);

            // Check if user is still logged in
            this.accountService.isLogin(oldToken).subscribe({
              next: (response) => {
                  localStorage.setItem('PearlCXUser', JSON.stringify(response));
                  this.loggedInUser = response;
              },
              error: (loginError) => {
                console.error('Error during login check:', loginError);
              },
            });
          }
        },
        error: (refreshError) => {
          console.error('Error during token refresh:', refreshError);
        },
      });
    }
  }

  logoutUserToken(token: string = '') {
    if (token && token !== '') {
      this.accountService.logoutToken(token).subscribe({
        next: () => {
          console.log(`Successfully logged out token: ${token}`);
        },
        error: (err) => {
          console.error('Error during token logout:', err);
        },
      });
    } else {
      this.accountService.logout().subscribe({
        next: () => {
          console.log('Successfully logged out');
        },
        error: (err) => {
          console.error('Error during logout:', err);
        },
      });
    }
  }

  notifyAuthChanged(u: User) {
    this.authChangeSubject.next(u);
  }

  loginUser(user: User, isAgent?: boolean) {
    if (user) {
      // there is a token, save the user
      localStorage.setItem('PearlCXUser', JSON.stringify(user));
      this.loggedInUser = user;
      this.isLoggedIn = true;

      this.notifyAuthChanged(user);

      if (isAgent) {
        this.router.navigate(['widget', 'agent']);
      } else {
        this.router.navigate(['']);
      }
    } else {
      this.logoutUser();
    }
  }

  checkPermission(permission: string): boolean {
    const user = this.getUser();
    return !!user?.authorization?.permissions.includes(permission);
  }
}
