<div class="side-bar">
  <div class="flex md:flex-column justify-content-center md:justify-content-start gap-2 align-items-center w-full">
    <a
    *ngFor="let link of navLinks; let i = index;"
    [tabindex]="i"
      class="custom-button w-5rem text-xs flex flex-column align-items-center"
      [ngClass]="{'active-link': isActive(link.path)}"
      (keydown.enter)="navigate(link.path)"
      (click)="navigate(link.path)">
      <i [class]="link.icon + ' mb-1 text-2xl'"></i>
      {{ link.label | translate }}
    </a>
  </div>
</div>
