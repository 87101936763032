import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../../../services/accounts.service';
import { SettingsService } from '../../../services/settings.service';
import { AuthorizationService } from '../../../services/authorization.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Region, regionList } from '../models/region.model';
import { ToastService } from '../../../services/toast.service';
import { SEVERITY } from '../../../models/severity.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  regionList: Region[] = regionList;
  regionDisplayNames: string[] = this.regionList.map(region => region.regionDisplayName);
  redirect!: string;
  token!: string;
  clientId!: string;
  isAgent = false;
  showBlank = false;
  regionId = '';
  userForm: FormGroup;
  state: string | null = null;

  constructor(
    private account: AccountsService,
    private activated: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private settings: SettingsService,
    private authService: AuthorizationService,
    private toastService: ToastService
  ) {
    this.redirect = `${this.settings.baseUrl}login`;

    // Initialize the form with FormBuilder
    this.userForm = this.fb.group({
      organizationName: new FormControl<string | null>(null, Validators.required),
      regionName: new FormControl<string | null>(null, Validators.required),
    });

    // Check for agent param and token
    this.activated.params.subscribe((params) => {
      if (params['agent']) {
        this.isAgent = true;
        this.showBlank = true;
      }
    });

    if (window.location.hash) {
      this.token = this.getParameterByName('access_token');
      this.state = decodeURIComponent(this.getParameterByName('state'));
      if (this.token) {
        const regionNameFromLocal = localStorage.getItem('regionName');
        if (regionNameFromLocal) {
          this.userForm.controls['regionName'].setValue(regionNameFromLocal);
        }
        this.showBlank = true;
        this.login();
      }
    }
  }

  ngOnInit(): void {
    this.setInitialValues();

    // Decode the redirect URL
    const encodedRedirect = this.activated.snapshot.queryParamMap.get('redirect');
    this.state = encodedRedirect ? decodeURIComponent(encodedRedirect) : null;
  }

  // Helper to get parameter from URL hash
  private getParameterByName(name: string): string {
    const regex = new RegExp(`[\\#&]${name}=([^&#]*)`),
      results = regex.exec(location.hash);
    return results ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : '';
  }

  // Set initial values from local storage
  setInitialValues(): void {
    const orgName = localStorage.getItem('organizationName');
    const regionName = localStorage.getItem('regionName');

    if (orgName) this.userForm.controls['organizationName'].setValue(orgName);
    if (regionName) {
      this.userForm.controls['regionName'].setValue(regionName);
      this.getRegion();
    }
  }

  getRegion() {
    const regionName = this.userForm.get('regionName')?.value;

    if (regionName) {
      // Find the region object based on RegionDisplayName
      const selectedRegion = this.regionList.find(
        (region) => region.regionDisplayName === regionName
      );

      this.regionId = selectedRegion ? selectedRegion.regionId : '';
    } else {
      this.regionId = '';
    }
  }

  // Retrieve client ID and credentials
  getClient(): void {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched(); // Show validation errors
      return;
    }

    const orgName = this.userForm.controls['organizationName'].value;
    const regionName = this.userForm.controls['regionName'].value;

    localStorage.setItem('organizationName', orgName);
    localStorage.setItem('regionName', regionName);

    this.account.getClientId(orgName, regionName).subscribe({
      next: (response) => {
        if (response) {
          this.clientId = response.clientId;
          console.log(this.clientId);
          this.getCredential();
        } else {
          this.toastService.add({ detail: response, severity: SEVERITY.ERROR });
        }
      },
      error: (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.toastService.add({ detail: 'register.ipnotwhitelisted', severity: SEVERITY.ERROR });
        }
      },
    });
  }

  // Login function with token
  login(): void {
    const state = this.state;
    const regionName = this.userForm.controls['regionName'].value;
    if (!regionName) return;

    this.account.relogin(this.token, regionName).subscribe({
      next: (response) => {
        if (response) {
          this.authService.saveAccessToken(response);
          this.authService.refreshToken(this.isAgent, state);
        }
      },
      error: (error) => {
        this.showBlank = false;
        console.error(error);
      },
    });
  }

  // Fetch credentials
  getCredential(): void {
    this.account.getCredential(this.clientId, this.redirect, this.regionId, this.state);
  }

  // Handle login button click
  onLogin(): void {
    this.getClient();
  }
}
