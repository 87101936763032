import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';

/**
 * Used for checking if possible to activate
 * the login route, if the user is logged in
 * we instantly move them to welcome or home.
 */
@Injectable()
export class LoginRouteGuard {
    constructor(
        private authService: AuthorizationService,
        private router: Router
    ) { }

    canActivate(): boolean {
        const isLoggedIn = this.authService.isUserLoggedIn();
        if (isLoggedIn) {
            this.router.navigate(['']);

            return false;
        } else {
            return true;
        }
    }
}
