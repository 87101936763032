import {
  Directive,
  ElementRef,
  Input,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { EditorView, basicSetup } from 'codemirror';
import { EditorState, Extension } from '@codemirror/state';
import { json } from '@codemirror/lang-json';
import { linter } from '@codemirror/lint';
import { jsonParseLinter } from '@codemirror/lang-json';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[app-json-editor]',
})
export class JsonEditorDirective
  implements AfterViewInit, OnDestroy, OnChanges
{
  @Input() control!: FormControl; // FormControl input
  private editorView!: EditorView;
  private subscription!: Subscription; // To track FormControl changes

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.initializeEditor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control && this.control) {
      // Reinitialize editor if the control changes
      this.initializeEditor();
    }
  }

  private initializeEditor(): void {
    if (!this.control) return;

    // Clean up the previous editor instance if it exists
    if (this.editorView) {
      this.editorView.destroy();
    }

    const linterExtension = linter(jsonParseLinter()); // Linter for JSON errors
    const editorElement = this.el.nativeElement;

    // Create an editor state with extensions
    const editorState = EditorState.create({
      doc: this.control.value
        ? JSON.stringify(this.control.value, null, 2)
        : '{}', // Default JSON document
      extensions: [basicSetup, json(), linterExtension, this.syncWithFormControl()],
    });

    // Create the CodeMirror editor view
    this.editorView = new EditorView({
      state: editorState,
      parent: editorElement,
    });

    // Subscribe to form control changes
    this.subscribeToControl();
  }

  private subscribeToControl(): void {
    // Unsubscribe from previous subscriptions, if any
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // Listen for value changes on the FormControl
    this.subscription = this.control.valueChanges.subscribe((value) => {
      if (this.editorView) {
        const editorText = this.editorView.state.doc.toString();
        const formattedValue = value ? JSON.stringify(value, null, 2) : '{}';

        // Only update if the value has changed
        if (formattedValue !== editorText) {
          this.editorView.dispatch({
            changes: { from: 0, to: editorText.length, insert: formattedValue },
          });
        }
      }
    });
  }

  // Sync the editor's content with the FormControl
  private syncWithFormControl(): Extension {
    return EditorView.updateListener.of((update) => {
      if (update.docChanged) {
        const value = update.state.doc.toString().trim();

        // Try parsing the JSON and update the form control
        try {
          const parsedJson = JSON.parse(value);

          // Update the FormControl with the parsed JSON if valid
          this.control.setValue(parsedJson, { emitEvent: false });
          this.control.setErrors(null); // Remove errors if valid
        } catch (error) {
          // Set FormControl to invalid if parsing fails
          this.control.setErrors({ invalidJson: true });
        }
      }
    });
  }

  // Clean up the editor and subscription when the directive is destroyed
  ngOnDestroy(): void {
    if (this.editorView) {
      this.editorView.destroy();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
