import { NgModule } from '@angular/core';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from '../shared/shared.module';
import { InstallComponent } from './install/install.component';

@NgModule({
  declarations: [
    ErrorPageComponent,
    LoginComponent,
    InstallComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
  ],
  providers: []
})
export class PagesModule { }
